import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGrowerResponse } from '../../Models/Responses/GrowerResponse';
import {
	IEnogenContractResponse,
	IStewardshipActivity,
	IStewardshipCompletionDatesResponse,
	IStewardshipField,
} from '../../Models/Responses/EnogenContractResponse';
import { getGrowers, updateGrowerInfo } from './GrowerThunks';
import {
	getPlans,
	updatePlanStatus,
	IPlans,
	downloadPlanPdf,	
	deletePlan,
	IDeleteResponse,
	downloadMaxscriptPdf,
} from './PlanThunks';
import { getFarms, saveFarm, deleteMultipleFarms, IFarms, IFarm } from './FarmThunks';
import { saveField, deleteField, deleteMultipleFields, updateField, IField } from './FieldThunks';
import { getDraftQuote, IDraftQuote, ISingleQuoteResponse, sendQuote } from './QuoteThunks';
import { getGrowerOrdersForYear, getOrderPlanProductsAndAssignments, importOrderPlanData, importGrowerOrderData, clearGrowerOrderData } from './OrderThunks';
import {
	getOrCreatePlantingPlan,
	IGrowerPlantingSummaryResponse,
	IGrowerFieldPlantingPlanResponse,
	retrievePlantingPlanSummaries,
	retrieveFieldPlantingPlan,
	updateFieldPlantingPlan,
	exportFieldPlantingPlan,
	downloadVRSPdf,
	updateManagementZones,
	resetPlantingPlanSummaries,
} from './PlantingPlanThunks';
import { IOrderPlanResponse } from '../../Models/Responses/OrderPlanResponse';
import { confirmBulkFields, updateSeedAssignmentsForField } from './SeedAssignmentThunks';
import { updateTargetYieldForField } from './TargetYieldThunk';
import { updateCropForFields } from './FieldCropThunks';
import { keyBy } from 'lodash';
import {
	getContractStewardshipData,
	getSellerEnogenContracts,
	saveContractStewardshipData,
	getStewardshipCompletionDates,
	updateContractStewardshipFieldLoss,
	updateContractStewardshipReplant,
	resendForSigning
} from './EnogenContractThunk';
import { ICompetitiveAllowanceResponse, IPlanCropDiscount, IPlanHybridDiscount } from '../../Models/Responses/CompetitiveAllowanceResponse';
import { getPlanDiscounts, updatePlanDiscounts } from '../CompetitiveAllowance/CompetitiveAllowanceThunks';

export interface IGrowerState
{
	ExpectingGrowerDataUserId: string | undefined;
	/**
	 * Which user the currently stored data was for, if any.
	 */
	StoredGrowerDataUserId: string | undefined;
	Growers: IGrowerResponse[];
	EnogenContracts: IEnogenContractResponse[];
	isLoadingEnogenContracts: boolean;
	isLoading: boolean;
	isLoadingGrowers: boolean;
	LoadingGrowerOrders: boolean;
	isLoadingFarms: boolean;
	isLoadingDraftQuotes: boolean;
	isLoadingPlans: boolean;
	isLoadingOrderPlanMaxScript: boolean;
	isLoadingBulkCropUpdateMaxScript: boolean;
	isLoadingPlanUpdate: boolean;
	isLoadingPlanDelete: boolean;
	isLoadingPlanLaunch: boolean;
	isLoadingPlanLaunchExisting: boolean;
	isLoadingPlanPdfDownload: boolean;
	isLoadingGetPlantingPlansSummaries: boolean;
	isLoadingPlantingPlansSummaries: boolean;
	isLoadingFieldPlantingPlan: boolean;
	isLoadingSaveManagementZone: boolean;
	isLoadingUpdateFieldPlantingPlan: boolean;
	isLoadingDownloadFieldPlantingPlan: boolean;
	isSavingFarm: boolean;
	isSavingField: boolean;
	isDeletingFarm: boolean;
	isDeletingField: boolean;
	loadingCounter: number;
	isError: boolean;
	isSuccess: boolean;
	showSuccessNotification: boolean;
	errorMessage: string;
	successMessage: string;
	redirectGHXUser: string;
}

const initialState: IGrowerState = {
	ExpectingGrowerDataUserId: undefined,
	StoredGrowerDataUserId: undefined,
	Growers: [],
	EnogenContracts: [],
	isLoadingEnogenContracts: false,
	isLoading: false,
	isLoadingGrowers: false,
	LoadingGrowerOrders: false,
	isLoadingFarms: false,
	isLoadingDraftQuotes: false,
	isLoadingPlans: false,
	isLoadingOrderPlanMaxScript: false,
	isLoadingBulkCropUpdateMaxScript: false,
	isLoadingPlanUpdate: false,
	isLoadingPlanDelete: false,
	isLoadingPlanLaunch: false,
	isLoadingPlanLaunchExisting: false,
	isLoadingPlanPdfDownload: false,
	isLoadingGetPlantingPlansSummaries: false,
	isLoadingPlantingPlansSummaries: false,
	isLoadingFieldPlantingPlan: false,
	isLoadingSaveManagementZone: false,
	isLoadingUpdateFieldPlantingPlan: false,
	isLoadingDownloadFieldPlantingPlan: false,
	isSavingFarm: false,
	isSavingField: false,
	isDeletingFarm: false,
	isDeletingField: false,
	loadingCounter: 0,
	isError: false,
	isSuccess: false,
	showSuccessNotification: false,
	errorMessage: undefined,
	successMessage: undefined,
	redirectGHXUser: undefined,
};

const loadingPrefix = 'isLoading';
const savingPrefix = 'isSaving';
const deletingPrefix = 'isDeleting';
const len = loadingPrefix.length;
// checks all loading flags (prefixed with 'isLoading') to return true on first match
const getIsLoading = (state: IGrowerState): boolean =>
	Object.keys(state)
		.filter(key => (key.startsWith(loadingPrefix) || key.startsWith(savingPrefix) || key.startsWith(deletingPrefix)) && key.substring(len).length)
		.some(isLoadingKey => state[isLoadingKey]);

export const growerSlice = createSlice({
	name: 'grower',
	initialState: initialState,
	reducers: {
		clearState: (state) => 
		{
			state = initialState;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;

			return state;
		},
		clearSuccess: (state) =>
		{
			state.isSuccess = false;
			state.showSuccessNotification = false;
			state.successMessage = undefined;
			state.redirectGHXUser = undefined;

			return state;
		},
		clearGamePlanLaunchUrl: (state, { payload }: PayloadAction<string>) =>
		{
			state.Growers.find(g => g.Id === payload).GamePlanLaunchUrl = undefined;
			return state;
		},
		updatePlanCropDiscounts: (state, { payload }: PayloadAction<{planId: String, cropLevelDiscounts: IPlanCropDiscount[], growerId: string}>) =>
		{
			state.Growers.find(g => g.Id === payload.growerId).Plans.find(p => 
				p.Id === payload.planId).CompetitiveAllowance.PlanCropDiscounts = [...payload.cropLevelDiscounts];
			
			return state;
		},
		updatePlanHybridDiscount: (state, { payload }: PayloadAction<{discountItem: IPlanHybridDiscount, growerId: string}>) =>
		{
			const foundIndex = state.Growers.find(g => g.Id === payload.growerId).Plans?.find(p => p.Id === payload.discountItem.PlanId)
				.CompetitiveAllowance?.PlanHybridDiscounts.findIndex(d => 
					d.Id && d.Id === payload.discountItem.Id || d.HybridId === payload.discountItem.HybridId);
			
			if (foundIndex > -1)
			{
				state.Growers.find(g => g.Id === payload.growerId).Plans.find(p => p.Id === payload.discountItem.PlanId)
					.CompetitiveAllowance.PlanHybridDiscounts[foundIndex] = payload.discountItem;
			}
			return state;
		},
		clearPlanCompetitiveAllowance: (state, { payload }: PayloadAction<{ planId: string, growerId: string}>) =>
		{
			const foundIndex = state.Growers.find(g => g.Id === payload.growerId).Plans?.findIndex(p => p.Id === payload.planId);
			if (foundIndex > -1)
			{
				state.Growers.find(g => g.Id === payload.growerId).Plans[foundIndex].CompetitiveAllowance = undefined;
			}

			return state;
		},
		updateStewardshipFields: (state, { payload }: PayloadAction<{contractId: string, fields: IStewardshipField[]}>) =>
		{
			state.EnogenContracts.find(c => c.ContractId === payload.contractId).StewardshipActivities[0].StewardshipActivity.Fields = payload.fields;
			return state;
		},
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getGrowers.pending, (state, action) =>
		{
			// Show a blocking load only if this is a first load or if this is for
			// a different user than we have data for.
			if(!state.Growers?.length || action.meta.arg.UserId !== state.StoredGrowerDataUserId)
			{
				state.isLoading = true;
				state.isLoadingGrowers = true;
				state.StoredGrowerDataUserId = undefined;
				state.Growers = [];
			}
			state.ExpectingGrowerDataUserId = action.meta.arg.UserId;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getGrowers.fulfilled, (state, { payload, meta }) =>
		{
			// If a different load has started, discard these results.
			if(state.ExpectingGrowerDataUserId && state.ExpectingGrowerDataUserId !== meta.arg.UserId)
			{
				return;
			}

			if(state.Growers?.length)
			{
				// Merge the list, otherwise we lose any previously downloaded data!
				const originalGrowerLookup = keyBy(state.Growers, (g) => g.Id);
				state.Growers = payload.map((g) => 
				{
					// If we didn't have this grower already, take it as-is
					if(!originalGrowerLookup[g.Id])
					{
						return g;
					}
					// Otherwise merge the new data on top of the existing data
					return {
						...originalGrowerLookup[g.Id],
						...g
					};
				});
			}
			else
			{
				state.Growers = payload;
			}
			state.StoredGrowerDataUserId = meta.arg.UserId;
			state.isLoadingGrowers = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getGrowers.rejected, (state, action) =>
		{
			// If a different load has started, discard these results.
			if(state.ExpectingGrowerDataUserId && state.ExpectingGrowerDataUserId !== action.meta.arg.UserId)
			{
				// But because there was an error, reset to allow new data.
				state.ExpectingGrowerDataUserId = undefined;
				return;
			}

			state.isLoadingGrowers = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.ExpectingGrowerDataUserId = undefined;
			state.StoredGrowerDataUserId = undefined;
			state.Growers = [];
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of Growers. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem getting the list of Growers. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the list of Growers. Please refresh and try again.';
			}
		});
		/**
		 * Farms
		 */
		builder.addCase(getFarms.pending, (state, { meta }) =>
		{
			if(!state.Growers.find(g => g.Id === meta.arg.growerId)?.Farms?.length)
			{
				state.isLoading = true;
				state.isLoadingFarms = true;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getFarms.fulfilled, (state, { payload }: PayloadAction<IFarms>) =>
		{
			state.isLoadingFarms = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
			
			// If a success message is passed in, display it
			state.successMessage = payload.SuccessMessage ?? payload.SuccessMessage;

			// Add farms to the grower
			state.Growers.find(g => g.Id === payload.RequestedGrowerId).Farms = payload.Farms;
		});
		builder.addCase(getFarms.rejected, (state, action) =>
		{
			state.isLoadingFarms = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of Farms. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem getting the list of Farms. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the list of Farms. Please refresh and try again.';
			}
		});
		builder.addCase(saveFarm.pending, (state) =>
		{
			state.isLoading = true;
			state.isSavingFarm = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(saveFarm.fulfilled, (state, { payload }: PayloadAction<IFarm>) =>
		{
			state.isSavingFarm = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;

			// Add farms to the grower
			state.Growers.find(g => g.Id === payload.RequestedGrowerId).Farms.push(payload.Farm);
		});
		builder.addCase(saveFarm.rejected, (state, action) =>
		{
			state.isSavingFarm = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to save the new Farm. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem saving the new Farm. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem saving the new Farm. Please refresh and try again.';
			}
		});
		builder.addCase(deleteMultipleFarms.pending, (state, action) =>
		{
			state.isLoading = true;
			state.isDeletingFarm = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(deleteMultipleFarms.rejected, (state, action) =>
		{
			state.isDeletingFarm = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to delete the Farms. Please refresh try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem deleting the Farms. Please refresh try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem deleting the Farms. Please refresh try again.';
			}
		});
		builder.addCase(deleteMultipleFarms.fulfilled, (state, { payload }) =>
		{
			state.isDeletingFarm = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;

			// Remove farms from datastore
			const grower = state.Growers.find(g => g.Id === payload.RequestedGrowerId);
			payload.Farms.forEach(farm => 
			{
				const deletedFarmIndex = grower.Farms.findIndex(f => f.Id === farm.Id);
				if (deletedFarmIndex > -1)
				{
					grower.Farms.splice(deletedFarmIndex, 1);
				}
			});
		});
		
		builder.addCase(saveField.pending, (state) =>
		{
			state.isLoading = true;
			state.isSavingField = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(saveField.fulfilled, (state, { payload }: PayloadAction<IField>) =>
		{
			state.isSavingField = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;

			// Add Fields to the matching Farm under the matching Grower
			state.Growers.find(g => g.Id === payload.RequestedGrowerId)
				.Farms.find(f => f.Id === payload.RequestedFarmId)
				.Fields.push(payload.Field);
		});
		builder.addCase(saveField.rejected, (state, action) =>
		{
			state.isSavingField = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to save the new Field. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem saving the new Field. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem saving the new Field. Please refresh and try again.';
			}
		});
		builder.addCase(deleteField.pending, (state, action) =>
		{
			state.isLoading = true;
			state.isDeletingField = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(deleteField.rejected, (state, action) =>
		{
			state.isDeletingField = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to delete the Field. Please refresh try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem deleting the Field. Please refresh try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem deleting the Field. Please refresh try again.';
			}
		});
		builder.addCase(deleteField.fulfilled, (state, { payload }) =>
		{
			state.isDeletingField = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(deleteMultipleFields.pending, (state, action) =>
		{
			state.isLoading = true;
			state.isDeletingField = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(deleteMultipleFields.rejected, (state, action) =>
		{
			state.isDeletingField = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to delete the Fields. Please refresh try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem deleting the Fields. Please refresh try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem deleting the Fields. Please refresh try again.';
			}
		});
		builder.addCase(deleteMultipleFields.fulfilled, (state, { payload }) =>
		{
			state.isDeletingField = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		/**
		 * Plans
		 */
		builder.addCase(getPlans.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingPlans = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getPlans.fulfilled, (state, { payload }: PayloadAction<IPlans>) =>
		{
			state.isLoadingPlans = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;

			state.Growers.find(g => g.Id === payload.RequestedGrowerId).Plans = payload.Plans;
		});
		builder.addCase(getPlans.rejected, (state, action) =>
		{
			state.isLoadingPlans = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				const { error, growerId } = action.payload;
				state.Growers.find(g => g.Id === growerId).Plans = [];
				if (error.indexOf('network') === -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of Plans. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem getting the list of Plans. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the list of Plans. Please refresh and try again.';
			}
		});
		builder.addCase(deletePlan.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingPlanDelete = true;
		});
		builder.addCase(deletePlan.rejected, (state, action) =>
		{
			state.isLoadingPlanDelete = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
		});
		builder.addCase(deletePlan.fulfilled, (state, { payload }: PayloadAction<IDeleteResponse>) =>
		{
			state.isLoadingPlanDelete = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			const { growerId, planId } = payload;
			const selectedGrowerInd = state.Growers.findIndex(g => g.Id === growerId);
			const planIndex = state.Growers[selectedGrowerInd].Plans.findIndex(plan => plan.Id === planId);
			if (planIndex > -1)
			{
				state.Growers[selectedGrowerInd].Plans.splice(planIndex, 1);
			}
		});
		builder.addCase(updatePlanStatus.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingPlanUpdate = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updatePlanStatus.fulfilled, (state, { payload }: PayloadAction<IPlans>) =>
		{
			state.isLoadingPlanUpdate = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;

			// Ignore the payload because it does not have the pricing on it
		});
		builder.addCase(updatePlanStatus.rejected, (state, action) =>
		{
			state.isLoadingPlanUpdate = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to update the status of the plan. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem updating the status of the plan. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem updating the status of the plan. Please refresh and try again.';
			}
		});
		builder.addCase(getGrowerOrdersForYear.pending, (state, action) =>
		{
			const growerId = action.meta.arg.growerId.toLowerCase();
			const grower = state.Growers.find(g => g.Id.toLowerCase() === growerId);
			const year = action.meta.arg.year.toString();
			state.LoadingGrowerOrders = true;
			state.isError = false;
			state.errorMessage = undefined;
			grower.OrdersByYear = {};
			grower.OrdersByYear[year] = [];
		});
		builder.addCase(getGrowerOrdersForYear.fulfilled, (state, action) =>
		{
			const growerId = action.meta.arg.growerId.toLowerCase();
			const grower = state.Growers.find(g => g.Id.toLowerCase() === growerId);
			const year = action.meta.arg.year.toString();

			state.LoadingGrowerOrders = false;
			state.isError = false;
			state.errorMessage = undefined;
			grower.OrdersByYear[year] = action.payload;
		});
		builder.addCase(getGrowerOrdersForYear.rejected, (state, action) =>
		{
			const growerId = action.meta.arg.growerId.toLowerCase();
			const grower = state.Growers.find(g => g.Id.toLowerCase() === growerId);
			const year = action.meta.arg.year.toString();

			state.LoadingGrowerOrders = false;
			state.isError = true;
			grower.OrdersByYear[year] = [];
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of Acre Proposal Orders. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = (action.payload as string);
				}
			}
			else
			{
				state.errorMessage = `There was a problem getting the list of Acre Proposal Orders for Grower ${growerId} in the year ${year}. Please refresh and try again.`;
			}
		});
		builder.addCase(downloadPlanPdf.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingPlanPdfDownload = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(downloadPlanPdf.fulfilled, (state, { payload }: PayloadAction<Blob>) =>
		{
			state.isLoadingPlanPdfDownload = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(downloadPlanPdf.rejected, (state, action) =>
		{
			state.isLoadingPlanPdfDownload = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to download the plan PDF. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem downloading the plan PDF. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem downloading the plan PDF. Please refresh and try again.';
			}
		});

		/**
		 * Draft Quotes
		 */
		builder.addCase(getDraftQuote.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingDraftQuotes = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getDraftQuote.fulfilled, (state, { payload }: PayloadAction<IDraftQuote>) =>
		{
			state.isLoadingDraftQuotes = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;

			// Add quotes to the grower
			state.Growers.find(g => g.Id === payload.RequestedGrowerId).DraftQuote = payload.Quote;
		});
		builder.addCase(getDraftQuote.rejected, (state, action) =>
		{
			state.isLoadingDraftQuotes = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the Quote. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = action.payload as string;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem setting up the Quote. Please refresh and try again.';
			}
		});
		/**
		 * SendQuote
		 */
		builder.addCase(sendQuote.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingDraftQuotes = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(sendQuote.fulfilled, (state, { payload }: PayloadAction<ISingleQuoteResponse>) =>
		{
			state.isLoadingDraftQuotes = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;

			// Add result to the grower
			state.Growers.find(g => g.Id === payload.RequestedGrowerId).DraftQuote = payload.Quote;

			state.isSuccess = true;
			const typeSent = payload.isGHX ? 'Order' : 'Proposal';
			state.redirectGHXUser = payload.isGHX && payload.SentToCropEdge ? '/fieldactivities/recall' : undefined;
			state.successMessage = payload.SentToCropEdge ? `${typeSent} successfully sent.` : 'Proposal successfully saved.';
		});
		builder.addCase(sendQuote.rejected, (state, action) =>
		{
			state.isLoadingDraftQuotes = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to send the Quote. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = action.payload as string;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem sending up the Quote. Please refresh and try again.';
			}
		});

		/**
		 * Planting Plans Get/Create
		 */
		builder.addCase(getOrCreatePlantingPlan.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingGetPlantingPlansSummaries = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getOrCreatePlantingPlan.fulfilled, (state, { payload }: PayloadAction<string>) =>
		{
			state.isLoadingGetPlantingPlansSummaries = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getOrCreatePlantingPlan.rejected, (state, action) =>
		{
			state.isLoadingGetPlantingPlansSummaries = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}

			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to start VRS for the Plan. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem starting VRS for the Plan. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem starting VRS for the Plan. Please refresh and try again.';
			}
		});

		/**
		 * Planting Plan Summaries
		 */
		builder.addCase(retrievePlantingPlanSummaries.pending, (state, { meta }) =>
		{
			// If we haven't already loaded, show a blocking spinner
			if(!state.Growers.find(g => g.Id === meta.arg.RequestedGrowerId)?.PlantingPlanSummary?.CropPlans.length)
			{
				state.isLoading = true;
				state.isLoadingPlantingPlansSummaries = true;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(retrievePlantingPlanSummaries.fulfilled, (state, { payload }: PayloadAction<IGrowerPlantingSummaryResponse>) =>
		{
			// Add the summaries to the grower => plan
			state.Growers.find(g => g.Id === payload.RequestedGrowerId).PlantingPlanSummary = payload.PlanSummaryData;
			state.isLoadingPlantingPlansSummaries = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(retrievePlantingPlanSummaries.rejected, (state, action) =>
		{
			state.isLoadingPlantingPlansSummaries = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of Planting Plan Summaries. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the list of Planting Plan Summaries. Please refresh and try again.';
			}
		});

		/**
		 * RESET Planting Plan Summaries
		 */
		builder.addCase(resetPlantingPlanSummaries.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingPlantingPlansSummaries = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(resetPlantingPlanSummaries.fulfilled, (state, { payload }: PayloadAction<IGrowerPlantingSummaryResponse>) =>
		{
			state.isLoadingPlantingPlansSummaries = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(resetPlantingPlanSummaries.rejected, (state, action) =>
		{
			state.isLoadingPlantingPlansSummaries = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to reset the Planting Plans for this Grower. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem resetting the Planting Plans for this Grower. Please refresh and try again.';
			}
		});

		/**
		 * Planting Plan Field Specific - Retrieve Field Planting Plan
		 */
		builder.addCase(retrieveFieldPlantingPlan.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingFieldPlantingPlan = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(retrieveFieldPlantingPlan.fulfilled, (state, { payload }: PayloadAction<IGrowerFieldPlantingPlanResponse>) =>
		{
			// Add the summaries to the grower => plan
			state.Growers.find(g => g.Id === payload.RequestedGrowerId)
				.PlantingPlanSummary.CropPlans.find(cp => cp.CropPlanId === payload.PlantingPlanData.CropPlanId)
				.PlantingPlanSummaries.find(pps => pps.FieldId === payload.PlantingPlanData.FieldId).PlantingPlanFieldData = payload.PlantingPlanData;

			state.isLoadingFieldPlantingPlan = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(retrieveFieldPlantingPlan.rejected, (state, action) =>
		{
			state.isLoadingFieldPlantingPlan = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to get the Field specific Planting Plan data. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the Field specific Planting Plan data. Please refresh and try again.';
			}
		});

		/**
		 * Planting Plan Field Specific - Update Field Planting Plan
		 */
		builder.addCase(updateFieldPlantingPlan.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingFieldPlantingPlan = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateFieldPlantingPlan.fulfilled, (state, { payload }: PayloadAction<IGrowerFieldPlantingPlanResponse>) =>
		{
			// Add the summaries to the grower => plan
			state.Growers.find(g => g.Id === payload.RequestedGrowerId)
				.PlantingPlanSummary.CropPlans.find(cp => cp.CropPlanId === payload.PlantingPlanData.CropPlanId)
				.PlantingPlanSummaries.find(pps => pps.FieldId === payload.PlantingPlanData.FieldId).PlantingPlanFieldData = payload.PlantingPlanData;

			state.isLoadingFieldPlantingPlan = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;

			state.isSuccess = true;
			state.successMessage = 'Planting Plan successfully saved.';
		});
		builder.addCase(updateFieldPlantingPlan.rejected, (state, action) =>
		{
			state.isLoadingFieldPlantingPlan = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to update the Field specific Planting Plan data. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem updating the Field specific Planting Plan data. Please try again.';
			}
		});

		// The start of generating and downloading a ZIP
		builder.addCase(exportFieldPlantingPlan.pending, (state, action) => 
		{
			state.isLoading = true;
			state.isLoadingDownloadFieldPlantingPlan = true;
			state.isError = false;
			state.errorMessage = undefined;
		});

		// Capture a success in generating a ZIP.  The actual payload is handled in the original thunk.
		builder.addCase(exportFieldPlantingPlan.fulfilled, (state) => 
		{
			state.isLoadingDownloadFieldPlantingPlan = false;
			state.isError = false;
			state.errorMessage = undefined;

			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
		});

		// Capture the failure in generating a ZIP
		builder.addCase(exportFieldPlantingPlan.rejected, (state, action) => 
		{
			state.isLoadingDownloadFieldPlantingPlan = false;
			state.isError = true;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			if (action.payload)
			{
				const error = action.payload as string;
				if (error.indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to export the planting plan. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = error;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem exporting the planting plan. Please refresh and try again.';
			}
		});

		// The start of generating and downloading a PDF
		builder.addCase(downloadVRSPdf.pending, (state, action) => 
		{
			state.isLoading = true;
			state.isLoadingDownloadFieldPlantingPlan = true;
			state.isError = false;
			state.errorMessage = undefined;
		});

		// Capture a success in generating a PDF.  The actual payload is handled in the original thunk.
		builder.addCase(downloadVRSPdf.fulfilled, (state) => 
		{
			state.isLoadingDownloadFieldPlantingPlan = false;
			state.isError = false;
			state.errorMessage = undefined;

			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
		});

		// Capture the failure in generating a PDF
		builder.addCase(downloadVRSPdf.rejected, (state, action) => 
		{
			state.isLoadingDownloadFieldPlantingPlan = false;
			state.isError = true;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			if (action.payload)
			{
				const error = action.payload as string;
				if (error.indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to export the planting plan as a PDF. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = error;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem exporting the planting plan as a PDF. Please refresh and try again.';
			}
		});

		/**
		* Planting Plan Field Specific - Update Management Zones on Planting Plan
		*/
		builder.addCase(updateManagementZones.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingSaveManagementZone = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateManagementZones.fulfilled, (state, { payload }: PayloadAction<IGrowerFieldPlantingPlanResponse>) =>
		{
		// Add the summaries to the grower => plan
			state.Growers.find(g => g.Id === payload.RequestedGrowerId)
				.PlantingPlanSummary.CropPlans.find(cp => cp.CropPlanId === payload.PlantingPlanData.CropPlanId)
				.PlantingPlanSummaries.find(pps => pps.FieldId === payload.PlantingPlanData.FieldId).PlantingPlanFieldData = payload.PlantingPlanData;
 
			state.isLoadingSaveManagementZone = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
 
			state.isSuccess = true;
			state.successMessage = 'Management Zone(s) successfully saved.';
		});
		builder.addCase(updateManagementZones.rejected, (state, action) =>
		{
			state.isLoadingSaveManagementZone = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to update the Field specific Management Zone data. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem updating the Field specific Management Zone data. Please try again.';
			}
		});

		/**
		* Order Plan MaxScript - Retrieve Product Listing and Assignments
		*/
		builder.addCase(getOrderPlanProductsAndAssignments.pending, (state, { meta }) =>
		{
			if(meta.arg.blocking !== false)
			{
				state.isLoading = true;
				state.isLoadingOrderPlanMaxScript = true;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getOrderPlanProductsAndAssignments.fulfilled, (state, { payload }: PayloadAction<IOrderPlanResponse>) =>
		{
			// Add the order plan data to the grower => order plan
			state.Growers.find(g => g.Id === payload.GrowerId).OrderPlan = payload;
 
			state.isLoadingOrderPlanMaxScript = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
 
			state.isSuccess = true;
		});
		builder.addCase(getOrderPlanProductsAndAssignments.rejected, (state, action) =>
		{
			state.isLoadingOrderPlanMaxScript = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to update the MaxScript data. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem updating the MaxScript data. Please try again.';
			}
		});

		/**
		* Order Plan MaxScript - Import Order
		*/
		builder.addCase(importOrderPlanData.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingOrderPlanMaxScript = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(importOrderPlanData.fulfilled, (state, { payload }: PayloadAction<object>) =>
		{
			// 10/14/2022 - Do not currently care about the import payload
			state.isLoadingOrderPlanMaxScript = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
 
			state.isSuccess = true;
		});
		builder.addCase(importOrderPlanData.rejected, (state, action) =>
		{
			state.isLoadingOrderPlanMaxScript = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to import the MaxScript data. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem importing the MaxScript data. Please try again.';
			}
		});

		/**
		* Order Plan MaxScript - Update Field Seed Assignment
		*/
		builder.addCase(updateSeedAssignmentsForField.pending, (state) =>
		{
			// Don't set a loading flag
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateSeedAssignmentsForField.fulfilled, (state, { payload }: PayloadAction<object>) =>
		{
			// 10/18/2022 - Do not currently care about the import payload
			
			// Don't set a loading flag
			state.isError = false;
			state.errorMessage = undefined;
 
			state.isSuccess = true;
			state.showSuccessNotification = true;
		});
		builder.addCase(updateSeedAssignmentsForField.rejected, (state, action) =>
		{
			// Don't set a loading flag			
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to update the Field Assignment data. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem updating the Field Assignment data. Please try again.';
			}
		});

		/**
		* Order Plan MaxScript - Update Field Target Yield
		*/
		builder.addCase(updateTargetYieldForField.pending, (state) =>
		{
			// Don't set a loading flag
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateTargetYieldForField.fulfilled, (state, { payload }: PayloadAction<object>) =>
		{
			// 10/18/2022 - Do not currently care about the import payload
			
			// Don't set a loading flag
			state.isError = false;
			state.errorMessage = undefined;
 
			state.isSuccess = true;
			state.showSuccessNotification = true;
		});
		builder.addCase(updateTargetYieldForField.rejected, (state, action) =>
		{
			// Don't set a loading flag			
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to update the MaxScript data. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem updating the MaxScript data. Please try again.';
			}
		});

		/**
		* Order Plan MaxScript - Update Field Crop
		*/
		builder.addCase(updateCropForFields.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingBulkCropUpdateMaxScript = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateCropForFields.fulfilled, (state, { payload }: PayloadAction<object>) =>
		{
			// 10/18/2022 - Do not currently care about the import payload			
			state.isLoadingBulkCropUpdateMaxScript = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
 
			state.isSuccess = true;
		});
		builder.addCase(updateCropForFields.rejected, (state, action) =>
		{
			state.isLoadingBulkCropUpdateMaxScript = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}

			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to update the MaxScript data. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem updating the MaxScript data. Please try again.';
			}
		});

		/**
		* Grower Order Import Field Activities - Import Order
		*/
		builder.addCase(importGrowerOrderData.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(importGrowerOrderData.fulfilled, (state, { payload }: PayloadAction<object>) =>
		{
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
			state.isSuccess = true;
		});
		builder.addCase(importGrowerOrderData.rejected, (state, action) =>
		{
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to import the Grower Order. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem importing the Grower Order data. Please try again.';
			}
		});

		builder.addCase(clearGrowerOrderData.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;		
		});

		builder.addCase(clearGrowerOrderData.fulfilled, (state) =>
		{
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
			state.isSuccess = true;
		});

		builder.addCase(clearGrowerOrderData.rejected, (state, action) =>
		{
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to clear the Grower Order. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem clearing the Grower Order data. Please try again.';
			}
		});

		/**
		 * Update/Edit an existing Field
		 */
		builder.addCase(updateField.pending, (state) =>
		{
			state.isLoading = true;
			state.isSavingField = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateField.fulfilled, (state, action) =>
		{
			state.isSavingField = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateField.rejected, (state, action) =>
		{
			state.isSavingField = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to update the Field. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem updating the Field. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem updating the Field. Please refresh and try again.';
			}
		});

		// Download Maxscript Order PDF
		builder.addCase(downloadMaxscriptPdf.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoadingPlanPdfDownload = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(downloadMaxscriptPdf.fulfilled, (state, { payload }: PayloadAction<Blob>) =>
		{
			state.isLoadingPlanPdfDownload = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(downloadMaxscriptPdf.rejected, (state, action) =>
		{
			state.isLoadingPlanPdfDownload = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to download the order PDF. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem downloading the order PDF. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem downloading the order PDF. Please refresh and try again.';
			}
		});

		// Download Seller Enogen Contract List
		builder.addCase(getSellerEnogenContracts.pending, (state) =>
		{
			state.isError = false;
			state.isLoadingEnogenContracts = true;
			state.errorMessage = undefined;
		});
		builder.addCase(getSellerEnogenContracts.fulfilled, (state, { payload }: PayloadAction<IEnogenContractResponse[]>) =>
		{
			state.EnogenContracts = payload;
			state.isLoadingEnogenContracts = false;
			state.isError = false;
			state.errorMessage = undefined;

			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
		});
		builder.addCase(getSellerEnogenContracts.rejected, (state, action) =>
		{
			state.isLoadingEnogenContracts = false;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			// TODO: 4/18/2024 Fix errors on backend and don't do anything with them for now
			// It throws an error if the user has no contracts and it should not
			/* state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of Enogen Contracts. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem retrieving the list of Enogen Contracts. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem downloading the Enogen Contracts. Please refresh and try again.';
			} */
		});

		// Download Enogen Contract Stewardship
		builder.addCase(getContractStewardshipData.pending, (state) =>
		{
			state.isError = false;
			state.isLoading = true;
			state.errorMessage = undefined;
		});
		builder.addCase(getContractStewardshipData.fulfilled, (state, { payload }: PayloadAction<IStewardshipActivity[]>) =>
		{
			if (payload && payload.length > 0)
			{
				state.EnogenContracts.find(f => f.ContractId === payload[0].ExternalReferenceId).StewardshipActivities = payload;
			}
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getContractStewardshipData.rejected, (state, action) =>
		{
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the Enogen Contract Stewardship Data. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem retrieving the Enogen Contract Stewardship Data. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem downloading the Enogen Contract Stewardship Data. Please refresh and try again.';
			}
		});

		// Save Enogen Contract Stewardship Edits
		builder.addCase(saveContractStewardshipData.pending, (state) =>
		{
			// Don't set a loading flag
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(saveContractStewardshipData.fulfilled, (state, { payload }: PayloadAction<IStewardshipActivity>) =>
		{
			// Don't set a loading flag
			state.isSuccess = true;
			state.showSuccessNotification = true;
			if (payload) // Ignore the payload
			{
				// Update only the Planting/Harvest statuses
				state.EnogenContracts.find(f => f.ContractId === payload.ExternalReferenceId).StewardshipActivities[0].PlantingStatus = payload.PlantingStatus;
				state.EnogenContracts.find(f => f.ContractId === payload.ExternalReferenceId).StewardshipActivities[0].HarvestStatus = payload.HarvestStatus;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(saveContractStewardshipData.rejected, (state, action) =>
		{
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to save the Enogen Contract Stewardship Data. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem saving the Enogen Contract Stewardship Data. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem saving the Enogen Contract Stewardship Data. Please refresh and try again.';
			}
		});

		// Get Stewardship Completion Dates for the CropYear -- no loading flags
		builder.addCase(getStewardshipCompletionDates.pending, (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getStewardshipCompletionDates.fulfilled, (state, { payload }: PayloadAction<{data: IStewardshipCompletionDatesResponse, contractId: string}>) =>
		{
			if (payload)
			{
				state.EnogenContracts.find(f => f.ContractId === payload.contractId).StewardshipCompletionDates = payload.data;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getStewardshipCompletionDates.rejected, (state, action) =>
		{
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the Enogen Contract Stewardship Completion Dates. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem retrieving the Enogen Contract Stewardship Completion Dates. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem downloading the Enogen Contract Stewardship Completion Dates. Please refresh and try again.';
			}
		});

		// Get Competitive Allowance for a Plan
		builder.addCase(getPlanDiscounts.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getPlanDiscounts.fulfilled, (state, { payload }: PayloadAction<{discounts: ICompetitiveAllowanceResponse, growerId: string}>) =>
		{
			if (payload && payload.discounts.PlanCropDiscounts?.length !== 0 && payload.discounts.PlanHybridDiscounts?.length !== 0)
			{
				state.Growers.find(g => g.Id === payload.growerId).Plans.find(p => 
					p.Id === payload.discounts?.PlanCropDiscounts[0].PlanId).CompetitiveAllowance = payload.discounts;
			}
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getPlanDiscounts.rejected, (state, action) =>
		{
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload.error as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the Competitive Allowance for the Plan. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem retrieving the Competitive Allowance for the Plan. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem retrieving the Competitive Allowance for the Plan. Please refresh and try again.';
			}
		});

		// Update Competitive Allowance for a Plan
		builder.addCase(updatePlanDiscounts.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updatePlanDiscounts.fulfilled, (state, { payload }: PayloadAction<{discounts: ICompetitiveAllowanceResponse, growerId: string}>) =>
		{
			if (payload && payload.discounts.PlanCropDiscounts?.length !== 0 && payload.discounts.PlanHybridDiscounts?.length !== 0)
			{
				state.Growers.find(g => g.Id === payload.growerId).Plans.find(p => 
					p.Id === payload.discounts?.PlanCropDiscounts[0].PlanId).CompetitiveAllowance = payload.discounts;
			}
			state.isSuccess = true;
			state.successMessage = 'Competitive Allowance saved successfully.';
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updatePlanDiscounts.rejected, (state, action) =>
		{
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload.error as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to update the Competitive Allowance for the Plan. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem updating the Competitive Allowance for the Plan. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem updating the Competitive Allowance for the Plan. Please refresh and try again.';
			}
		});

		// Save Enogen Contract Stewardship Replant
		builder.addCase(updateContractStewardshipReplant.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateContractStewardshipReplant.fulfilled, (state) =>
		{
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isSuccess = true;
			state.successMessage = 'Field Replant status set successfully.';
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateContractStewardshipReplant.rejected, (state, action) =>
		{
			state.isError = true;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to save the Enogen Contract Replant Data. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem saving the Enogen Contract Replant Data. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem saving the Enogen Contract Replant Data. Please refresh and try again.';
			}
		});

		// Save Enogen Contract Stewardship Field Lost
		builder.addCase(updateContractStewardshipFieldLoss.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateContractStewardshipFieldLoss.fulfilled, (state) =>
		{
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isSuccess = true;
			state.successMessage = 'Field Lost status set successfully.';
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateContractStewardshipFieldLoss.rejected, (state, action) =>
		{
			state.isError = true;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to save the Enogen Contract Field Loss Data. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem saving the Enogen Contract Field Loss Data. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem saving the Enogen Contract Field Loss Data. Please refresh and try again.';
			}
		});
	
		// Send Enogen contract for resigning
		builder.addCase(resendForSigning.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(resendForSigning.fulfilled, (state) =>
		{
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isSuccess = true;
			state.successMessage = 'Contract has been sent for signing successfully.';
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(resendForSigning.rejected, (state, action) =>
		{
			state.isError = true;
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to send the Enogen Contract for resigning. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem sending the Enogen Contract for resigning. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem sending the Enogen Contract for resigning. Please refresh and try again.';
			}
		});

		// Update Grower Request
		builder.addCase(updateGrowerInfo.pending, (state) =>
		{
			// Don't set a loading flag
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateGrowerInfo.fulfilled, (state, { payload }: PayloadAction<IGrowerResponse>) =>
		{
			// Don't set a loading flag
			state.isSuccess = true;
			state.showSuccessNotification = true;
			if (payload)
			{
				// Cannot replace grower model with this projection as it will lose season data so just update the specific values that
				// can be updated on the request
				const growerIndex = state.Growers.findIndex(g => g.Id === payload.Id);
				state.Growers[growerIndex].LanguagePreference = payload.LanguagePreference;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(updateGrowerInfo.rejected, (state, action) =>
		{
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to import the Grower Order. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem importing the Grower Order data. Please try again.';
			}
		});

		/**
		 * Bulk Confirm Fields
		 */
		builder.addCase(confirmBulkFields.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(confirmBulkFields.fulfilled, (state, { payload }: PayloadAction<object>) =>
		{
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = false;
			state.errorMessage = undefined;
	
			state.isSuccess = true;
			state.redirectGHXUser = '/fieldactivities';
			state.successMessage = 'Fields successfully confirmed.';
		});
		builder.addCase(confirmBulkFields.rejected, (state, action) =>
		{
			if (!getIsLoading(state))
			{
				state.isLoading = false;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to confirm the fields. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = action.payload as string;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem confirming the fields. Please refresh and try again.';
			}
		});
	}
});

export const {
	clearGamePlanLaunchUrl,
	clearState,
	clearError,
	clearSuccess,
	updatePlanHybridDiscount,
	clearPlanCompetitiveAllowance,
	updatePlanCropDiscounts,
	updateStewardshipFields,
} = growerSlice.actions;
